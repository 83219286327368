h1 {
  color: white;
  font-weight: lighter;
  transition: font-weight .5s;
}

h1:hover {
  font-weight: 300;
  cursor: pointer;
  transition: font-weight .5s;
}

div {
  min-height: 100svh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}